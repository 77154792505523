import React from "react";
import Footer from "../components/footer";

const NotFound = () => {
  return (
    <div>
      <h1>404 - Page Not Found</h1>
      <Footer />
    </div>
  );
};

export default NotFound;
